#section-quality {
  margin: 60px 0;

  .op-quality-content {
    position: relative;
    width: 100%;
    height: auto;

    .op-vl {
      position: absolute;
      top: 18px;
      width: 1px;
      height: 0;
      background-color: white;

      &.left {
        left: 5%;
      }

      &.right {
        left: 95%;
      }

      @include media-breakpoint-up(md) {
        top: 16px;
        height: 431px;
      }

      @include media-breakpoint-up(lg) {
        top: 18px;
        height: 497px;

        &.left {
          left: 10%;
        }

        &.right {
          left: 90%;
        }
      }

      @include media-breakpoint-up(xl) {
        top: 20px;
        height: 565px;
      }
    }

    h3 {
      font-size: 30px;

      @include media-breakpoint-up(md) {
        font-size: 28px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 30px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 34px;
      }
    }

    .op-hl {
      margin: 0;

      span {
        margin: 0 5px;
      }

      @include media-breakpoint-up(md) {
        display: flex;
        flex-basis: 100%;
        align-items: center;
        margin: 8px 5%;

        &:before,
        &:after {
          content: '';
          flex: 1;
          border-bottom: 1px solid $color-white;
          margin: auto;
        }

        &:before {
          margin-right: 10px;
        }

        &:after {
          margin-left: 10px;
        }
      }

      @include media-breakpoint-up(lg) {
        margin: 8px 10%;
      }
    }

    #op-cuboid-container {
      position: relative;
      margin: 0 auto;
      width: 100%;
      height: 0;
      padding-bottom: 50%;

      canvas {
        cursor: grab;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
      }
    }

    ul {
      padding-left: 0;
      text-align: center;
      list-style-type: none;

      li {
        text-transform: uppercase;
      }
    }

    .op-quality-outro {
      margin-top: 70px;

      p {
        font-size: 6.5vw;
      }

      @include media-breakpoint-up(md) {
        p {
          font-size: calc(1.425rem + 2.1vw);
        }
      }

      @include media-breakpoint-up(lg) {
        p {
          font-size: 50px;
        }
      }
    }
  }
}
