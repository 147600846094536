#section-who {
  position: relative;
  margin: 60px 0 0 0;
  // background-color: rgba(255, 0, 0, 0.5);

  #op-cube-container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 0;
    padding-bottom: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
      padding-bottom: 50%;
    }

    canvas {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      cursor: grab;
    }
  }

  .op-who-bg-image {
    z-index: -1;
    top: 0;
    left: 0;
    width: 50%;
    height: 695px;
    position: absolute;
    background-repeat: no-repeat;
    background-position: left;
    background-size: right;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    @include media-breakpoint-up(md) {
      width: 100%;
      background-size: cover;
      background-position: center;
    }

    .op-who-bg-copy-container {
      position: relative;
      top: 100%;
      width: 200%;
      overflow: hidden;

      .op-who-bg-copy {
        position: relative;
        text-transform: uppercase;

        font-size: 30vw;
        line-height: 0.69;

        color: transparent;

        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: white;

        &.color-pink {
          -webkit-text-stroke-color: $color-pink;
        }
        &.color-blue {
          -webkit-text-stroke-color: $color-blue;
        }
        &.color-purple {
          -webkit-text-stroke-color: $color-purple;
        }
        &.color-orange {
          -webkit-text-stroke-color: $color-orange;
        }

        white-space: nowrap;
        opacity: 0.3;

        @include media-breakpoint-up(md) {
          font-size: 260px;
          -webkit-text-stroke-width: 2px;
        }

        @include media-breakpoint-up(md) {
          -webkit-text-stroke-width: 3px;
        }
      }

      @include media-breakpoint-up(md) {
        width: 100%;
      }
    }

    #op-who-bg-copy-2 {
      font-size: 34vw;
      // margin-left: -50%;

      @include media-breakpoint-up(md) {
        // margin-left: 0;
        font-size: 320px;
      }
    }

    #op-who-bg-copy-3 {
      font-size: 26vw;

      @include media-breakpoint-up(md) {
        font-size: 160px;
      }
    }
  }

  .op-h1-colored {
    margin-left: -28px;
    font-size: 39vw;
    float: left;

    @include media-breakpoint-up(sm) {
      font-size: 190px;
      margin-left: 0;
    }

    @include media-breakpoint-up(md) {
      font-size: 200px;
      margin-left: -100px;
      float: right;
    }

    @include media-breakpoint-up(lg) {
      font-size: 210px;
      margin-right: 60px;
    }
  }

  .op-who-copy-container {
    .colored {
      font-size: calc(1.425rem + 4.1vw);
      line-height: 0;
      white-space: pre-wrap;

      @include media-breakpoint-up(md) {
        font-size: 40px;
      }
    }

    p {
      margin-left: 26%;
      margin-bottom: 40px;

      @include media-breakpoint-up(md) {
        margin-left: 0;
      }
    }
  }
}
