#op-header {
  display: flex;
  justify-content: space-between;

  .op-bg-copy-container {
    height: 100vh;
    // background-color: rgba(0, 0, 255, 0.5);

    .op-bg-copy-inner {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      // background-color: rgba(255, 0, 0, 0.5);
    }
  }

  #op-logo-cube-container {
    position: relative;
    float: left;
    margin: 15px;
    width: 140px;
    height: 140px;

    canvas {
      position: absolute;
      cursor: grab;
      width: 100%;
      height: 100%;
      left: 0;
    }
  }

  #op-contact {
    display: none;
    @include media-breakpoint-up(md) {
      display: inline-block;
      float: left;
      line-height: 1.2;
      margin: 45px 0 0 4px;
    }
  }

  .op-menu {
    margin: 42px 15px 0 0;
    text-transform: uppercase;

    ul {
      margin-bottom: 0;

      li {
        display: inline-block;
        pointer-events: auto;

        &:first-child {
          margin-right: 10px;
        }

        .dot {
          margin-left: 10px;
          pointer-events: none;
        }

        &:nth-child(2) {
          .dot {
            display: none;
          }
        }

        a {
          display: inline-block;
          padding: 0 6px;
          text-align: center;
          color: white;
          background: transparent;

          background: linear-gradient(
              to left,
              transparent 50%,
              $color-purple 50%
            )
            right;
          background-size: 204%;
          transition: all 0.35s cubic-bezier(0, 0, 0.23, 1);

          &:hover {
            background-position: left;
          }
        }

        &.active {
          pointer-events: none;
          a {
            background-position: left;
          }
        }
      }
    }

    .op-menu-meta-item {
      float: right;
      margin-right: 5px;
    }

    #op-insta-icon {
      width: 20px;
      height: auto;

      svg {
        width: 20px;
        height: auto;

        path {
          transition: all 0.35s cubic-bezier(0, 0, 0.23, 1);
          fill: $color-white;
        }

        &:hover {
          path {
            fill: $color-purple;
          }
        }
      }
    }
  }
}
