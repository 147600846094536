.op-bg-copy-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0;
  padding: 0;
  z-index: -1;

  .op-bg-copy {
    text-transform: uppercase;

    font-size: 80vw;
    line-height: 0.72;

    color: transparent;

    white-space: nowrap;
    opacity: 0.3;

    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;

    &.color-pink {
      -webkit-text-stroke-color: $color-pink;
    }
    &.color-blue {
      -webkit-text-stroke-color: $color-blue;
    }
    &.color-purple {
      -webkit-text-stroke-color: $color-purple;
    }
    &.color-orange {
      -webkit-text-stroke-color: $color-orange;
    }

    @include media-breakpoint-up(md) {
      font-size: 330px;
      -webkit-text-stroke-width: 2px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 350px;
      -webkit-text-stroke-width: 3px;
    }
  }
}
