#section-outro {
  position: relative;
  margin: -80px 0 180px 0;
  // background-color: rgba(0, 0, 255, 0.5);

  p {
    margin-left: 13%;

    @include media-breakpoint-up(md) {
      margin-left: 0;
    }
  }

  .op-projects-col {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    z-index: -1;

    @include media-breakpoint-up(md) {
      padding-right: calc(var(--bs-gutter-x) / 2);
    }

    #op-projects {
      width: 100%;
      max-width: 830px;
      margin-bottom: 80px;
      float: right;

      @include media-breakpoint-up(md) {
        margin-bottom: 80px;
      }

      a {
        transition: all 0.35s cubic-bezier(0, 0, 0.23, 1);
        // filter: brightness(100%);
        // transform: scale(1, 1);

        &:hover {
          img,
          video {
            transform: scale(1.1, 1.1);
            filter: brightness(50%);
          }
        }
      }

      /* clear fix */
      .grid:after {
        content: '';
        display: block;
        clear: both;
      }

      .grid-sizer,
      .grid-item {
        // width: 33.3333333%;
        width: 25%;
        height: auto;
      }

      .grid-item {
        float: left;
        margin-bottom: 0;
        overflow: hidden;

        video,
        img {
          transition: all 0.35s cubic-bezier(0, 0, 0.23, 1);
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .op-copy-col {
    .op-copy-container {
      margin-top: 0;
      z-index: 99;

      @include media-breakpoint-up(md) {
        margin-top: 20%;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 15%;
      }

      .colored {
        font-size: calc(1.425rem + 4.1vw);
        line-height: 0;
        white-space: pre-wrap;

        @include media-breakpoint-up(md) {
          font-size: 40px;
        }
      }

      p {
        margin-left: 10%;
        margin-right: 10%;

        @include media-breakpoint-up(md) {
          width: 260px;
          margin-left: 0;
          margin-right: 0;
        }

        @include media-breakpoint-up(lg) {
          width: 326px;
        }

        @include media-breakpoint-up(xl) {
          width: 376px;
        }
      }

      .op-logo-gallery {
        margin: 80px 0;
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 260px;
        }

        @include media-breakpoint-up(lg) {
          width: 326px;
        }

        @include media-breakpoint-up(xl) {
          width: 376px;
        }

        img {
          padding: 15px;
          width: 100%;
          // height: 70px;
        }

        a {
          opacity: 0.5;
          transition: opacity 0.2s ease-in-out;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .op-vertical-container {
    position: relative;
    width: 100%;
    height: auto;
    // background-color: rgba(0, 0, 255, 0.5);

    .op-vertical-line-container-1 {
      height: 100vh;
    }

    .op-vertical-line-container-2 {
      height: 50vh;
    }

    .op-vertical-line-container-3 {
      height: 25vh;
    }

    .op-vertical {
      // width: 0;
      margin: 0 0 0 50%;
      // border: 1px solid white;
      opacity: 1;

      border: none;
      width: 1px;
      height: 1px;
      /* Set the hr color */
      color: $color-white; /* old IE */
      background-color: $color-white; /* Modern Browsers */

      &.line-1 {
        height: 0;
      }
      &.line-2 {
        height: 0;
      }
      &.line-3 {
        height: 0;
      }
    }

    #op-thanks {
      margin: 40px;
    }

    #op-bussi {
      margin: 60px 0 0 0;
    }

    #op-dancing {
      margin: 80px;
      text-align: center;
      video {
        width: 100%;
        height: auto;

        @include media-breakpoint-up(md) {
          width: 260px;
        }
      }
    }

    #op-emoji {
      position: relative;
      margin: 85px 5px 0 5px;
      width: 50%;
      left: 25%;
    }
  }
}
