#section-intro {
  position: relative;
  margin: 110px 0;
  // background-color: rgba(255, 0, 0, 0.5);

  @include media-breakpoint-down(md) {
    .row > * {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .op-intro-copy {
    width: 100%;
    margin-top: -17%;
    margin-left: 0;
    padding-left: 2%;

    @include media-breakpoint-up(md) {
      margin-top: -450px;
      margin-left: -150px;
    }

    .op-h1-1 {
      font-size: calc(1.425rem + 2.1vw);
      line-height: 1;
    }

    p {
      font-size: 24px;
      margin-left: 10%;
      margin-right: 10%;

      @include media-breakpoint-up(md) {
        width: 70%;
        margin-left: 70px;
        margin-right: 0;
      }
    }
  }

  .op-intro-image {
    position: absolute;
    width: 100%;
    height: auto;
    right: 0;
    max-width: 50%;
    z-index: -1;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      position: relative;
      max-width: 312px;
      margin-left: 52%;
    }
  }

  .op-intro-bg-copy-container {
    position: absolute;
    overflow: inherit;
    width: 90%;
    height: 200%;
    margin: 0;
    padding: 0;
    max-width: 1240px;
    z-index: -1;
    top: 120%;
    left: 50%;
    transform: translate(-50%, 0);
    // background-color: rgba(255, 0, 0, 0.25);

    @include media-breakpoint-up(md) {
      width: 92%;
      top: 140%;
    }

    @include media-breakpoint-up(lg) {
      top: 150%;
    }

    @include media-breakpoint-up(xl) {
      top: 200%;
    }

    .op-intro-bg-copy {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);

      font-size: 14vh;
      line-height: 0.7;
      // background-color: $color-pink;

      @include media-breakpoint-up(md) {
        font-size: 180px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 220px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 260px;
      }
    }

    #op-intro-bg-copy-1 {
      top: 400px;
      left: 0;

      @include media-breakpoint-up(md) {
        top: 510px;
      }
      @include media-breakpoint-up(lg) {
        top: 660px;
      }
      @include media-breakpoint-up(xl) {
        top: 780px;
      }
    }

    #op-intro-bg-copy-2 {
      top: 200px;
      left: 10vh;

      @include media-breakpoint-up(md) {
        top: 410px;
        left: 128px;
      }
      @include media-breakpoint-up(lg) {
        top: 500px;
        left: 150px;
      }
      @include media-breakpoint-up(xl) {
        top: 570px;
        left: 180px;
      }
    }

    #op-intro-bg-copy-3 {
      top: 0;
      left: calc(100% - 20vh);
      transform: rotate(90deg);
      transform-origin: 0% 100%;
      transform-style: preserve-3d;

      @include media-breakpoint-up(md) {
        top: 240px;
        left: calc(100% - 260px);
      }
      @include media-breakpoint-up(lg) {
        top: 220px;
        left: calc(100% - 316px);
      }
      @include media-breakpoint-up(xl) {
        top: 260px;
        left: calc(100% - 370px);
      }
    }

    #op-intro-bg-copy-4 {
      top: 610px;
      left: 100%;
      transform: rotate(-90deg);
      transform-origin: 0% 100%;
      transform-style: preserve-3d;

      @include media-breakpoint-up(md) {
        top: 800px;
      }
      @include media-breakpoint-up(lg) {
        top: 980px;
      }
      @include media-breakpoint-up(xl) {
        top: 1150px;
      }
    }
  }

  .op-intro-mouth-move-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    background: transparent;
    pointer-events: none;

    .op-intro-mouth-video-container {
      position: absolute;
      z-index: 1;
      visibility: hidden;
      display: none;
      pointer-events: auto;

      @include media-breakpoint-up(md) {
        top: 24%;
        left: 68%;
        width: 200px;
        transform: translate(5%, 5%);
        display: block;
      }

      video {
        width: 100px;
        height: auto;
        cursor: pointer;

        @include media-breakpoint-up(md) {
          width: 110px;
        }

        @include media-breakpoint-up(lg) {
          width: 120px;
        }
      }

      p {
        position: relative;
        margin-top: -20px;
        margin-left: 10px;
        font-size: 18px;

        @include media-breakpoint-up(md) {
          font-size: 21px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 24px;
        }

        span {
          cursor: pointer;
        }
      }
    }
  }
}
