#op-imprint {
  position: relative;
  margin: 180px 0;

  a {
    font-size: 24px;
    line-height: 1.3;
  }

  h1 {
    padding-bottom: 20px;
  }

  h2 {
    padding-top: 30px;
    font-size: calc(1rem + 1.25vw);
  }
}
