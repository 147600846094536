#section-about {
  position: relative;
  margin: 60px 0;
  // background-color: rgba(0, 0, 255, 0.5);

  .op-about-h2-container {
    margin: 60px 0;

    .op-about-h2 {
      margin: 2px 0 10px 10px;

      font-size: 7.5vw;

      @include media-breakpoint-up(sm) {
        font-size: 6.4vw;
      }

      @include media-breakpoint-up(md) {
        font-size: calc(1.425rem + 2.1vw);
      }

      @include media-breakpoint-up(lg) {
        font-size: 50px;
      }

      span {
        line-height: 1.4;
        padding: 2px 10px;
        white-space: pre-wrap;
      }

      @include media-breakpoint-up(md) {
        margin-left: 105px;
      }
    }
  }

  .op-employee-gallery {
    --bs-gutter-x: 0;

    a {
      position: relative;
      display: block;
      margin: 0;
      padding: 0;
      width: 100%;
      height: auto;
      overflow: hidden;

      .op-emplyoee-gallery-item {
        transition: all 0.35s cubic-bezier(0, 0, 0.23, 1);
        position: absolute;
        bottom: 0;
        left: 10px;
        text-transform: uppercase;
        user-select: none;
        width: calc(100% - 20px);

        .bg-colored-empl {
          font-size: 18px;
          line-height: 0;
          padding: 2px 6px;
          width: 100%;
          opacity: 0;
          background-image: linear-gradient(
            120deg,
            $color-purple 0%,
            $color-purple 100%
          );
          background-repeat: no-repeat;
          background-size: 0 100%;
          background-position: 0 100%;
          white-space: pre-wrap;
          transition: all 0.35s cubic-bezier(0, 0, 0.23, 1);
          transition-delay: 0;
        }

        &.op-isempty {
          display: none;
        }
      }

      img,
      video {
        display: block;
        transition: all 0.35s cubic-bezier(0, 0, 0.23, 1);
        width: 100%;
        height: auto;
        filter: brightness(100%);
        transform: scale(1, 1);
      }

      &:hover {
        .op-emplyoee-gallery-item {
          bottom: 10px;

          .bg-colored-empl {
            opacity: 1;
            background-size: 100% 100%;

            &:nth-of-type(1) {
              transition-delay: 0.1s;
            }
            &:nth-of-type(2) {
              transition-delay: 0.2s;
            }
          }
        }

        img,
        video {
          transform: scale(1.1, 1.1);
          filter: brightness(50%);
        }
      }
    }
  }

  .op-employee-content {
    margin-top: 40px;
  }

  p {
    margin-left: 10%;
    margin-right: 10%;

    @include media-breakpoint-up(md) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
