html {
  -webkit-font-smoothing: antialiased;
}

html,
body {
  .op-prevent-scrolling {
    overflow: hidden;
    height: 100vh;
  }
}

body {
  font-family: $font-primary;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $color-black;
  color: $color-white;

  background-image: url('/assets/images/bg.jpg');

  a {
    text-decoration: none;
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }

  p {
    font-size: 24px;
    line-height: 1.3;
  }

  .op-a {
    @include media-breakpoint-up(md) {
      transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(
        90deg,
        $color-purple,
        $color-purple 50%,
        $color-white 0
      );
      background-size: 200% 100%;
      background-position: 100%;
      color: $color-white;
      text-align: left;

      &:hover {
        background-position: 0;
        color: #0300e2;
      }

      &.reverse {
        background-image: linear-gradient(
          90deg,
          $color-white,
          $color-white 50%,
          $color-purple 0
        );
        &:hover {
          color: $color-white;
        }
      }
    }
  }

  .colored {
    background-color: white;
    background-image: linear-gradient(
      90deg,
      $color-purple,
      $color-purple 50%,
      $color-white 0
    );

    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;

    background-size: 200% 200%;
    background-position: 100%;

    color: $color-white;
    text-align: left;
    white-space: pre-wrap;
  }

  .flip {
    display: inline-block;
    -webkit-transform: scaleX(-1, 0);
    transform: scale(-1, 1);
    // position: absolute;
    // -moz-transform-origin: 0 0;
  }

  .bg-colored {
    background-image: linear-gradient(
      120deg,
      $color-purple 0%,
      $color-purple 100%
    );

    background-repeat: no-repeat;
    background-size: 0 100%;
    background-position: 0 100%;
    white-space: pre-wrap;
  }

  .op-h1-colored {
    line-height: 1;
    font-family: $font-secondary;
    font-size: 38vw;
    text-transform: uppercase;
    background: linear-gradient($color-pink, $color-blue);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-top: -12px;
    margin-left: -10px;

    span {
      background: linear-gradient($color-pink, $color-blue);
      @supports (-moz-appearance: none) {
        background: none;
      }
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    @include media-breakpoint-up(sm) {
      margin-left: 0;
      font-size: 190px;
    }

    @include media-breakpoint-up(md) {
      font-size: 200px;
      margin-left: -18px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 210px;
      margin-left: -20px;
    }
  }

  .split-parent {
    overflow: hidden;
  }

  .split-child {
    display: inline-block;
  }

  .op-link-isempty {
    pointer-events: none;
  }
}
