$font-primary: 'alternate_gothic_no3_d_regular';
$font-secondary: 'bodoniflf-roman';

// EMBED
@font-face {
  font-family: $font-primary;
  src: url("/assets/fonts/alternate_gothic_no3_d_regular-webfont.woff2")
      format("woff2"),
    url("/assets/fonts/alternate_gothic_no3_d_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: $font-secondary;
  src: url('/assets/fonts/bodoniflf-roman.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

:root {
  font-size: 1rem;
}
